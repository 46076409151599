import React, { useState, useEffect, useContext, Fragment, forwardRef, createRef } from 'react';
import { observer } from 'mobx-react-lite'
import clsx from 'clsx';
import { Responsive, WidthProvider } from "react-grid-layout";

const ResponsiveGridLayout = WidthProvider(Responsive);


const GridHoc = observer(function GridHoc(props) {
    
    const { screen, children } = props;
    const {layouts, cols, breakpoints} = screen.layout
    const edition_mode = screen.editor ? screen.editor.design_mode:false
    // const edition_mode = true
    function onLayoutChange(layout, layouts){
        if(edition_mode){
            console.log("THE SAVED LAYOUT")
            console.log(layout)
            screen.save_view_layout(layouts, screen.editor.current_breakpoint)
        }
    }

    function onBreakpointChange(newBreakpoint, newCols){
        screen.editor.set_current_breakpoint(newBreakpoint)
        
        
    }

    console.log("CHILDRENS ON MOUNT HOC")
    console.log(children)
    
    return (
                <>
                    <ResponsiveGridLayout
                        className="layout"
                        layouts={layouts}
                        breakpoints={breakpoints}
                        rowHeight={42}
                        compactType='vertical'
                        margin={[10,10]}
                        allowOverlap={false}
                        preventCollision={false}
                        isDraggable= {edition_mode}
                        isResizable = {edition_mode}
                        onLayoutChange={(layout, layouts) =>
                            onLayoutChange(layout, layouts)
                          }
                        onBreakpointChange={onBreakpointChange}
                        cols={cols}
                        
                    >

                        {children}


                    </ResponsiveGridLayout>

                
            
        </>


    )

});

export default GridHoc;


