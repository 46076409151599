import React from 'react';
import Field from '../../ui/fields/Field';
import CheckBox from '../../ui/fields/CheckBox';
import FormButton from '../../ui/buttons/FormButton.js'
import Container from '../../ui/surfaces/Container'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx';

const field_renderers = ['binary', 'formula']

// const fieldColspan = {
//     1: "md:col-span-1",
//     2: "md:col-span-2",
//     3: "md:col-span-3",
//     4: "md:col-span-4",
//     5: "md:col-span-4"
// }

const FieldValue = observer(function FieldValue(props) {
    const { field, record } = props;
    const ftype = field.type

    function getFieldValue(){
        if(ftype === 'html'){
            return (<div contentEditable='false' dangerouslySetInnerHTML={{ __html:field.get_string_value(record) }}></div>)
        }        
        else if(field_renderers.includes(ftype)){
            return (
                <Field
                    field={field}
                    record={record}
                    variant="outlined"
                    noWrap

                />
            )
        }
        return (<React.Fragment>{field.get_string_value(record)}</React.Fragment>)
    }

    return (
        <div className={"text-xs text-gray-600 font-medium ".concat(field.grid_cls)}>
            {getFieldValue()}

        </div>
    )
})

const FieldsGrid = observer(function FieldsGrid(props) {
    const { fields, record, default_columns } = props;
    const columns_classname = "grid-cols-"+default_columns
    return (
        <div className={"flex-grow grid w-full gap-y-5 gap-x-4 py-4 px-4 "+columns_classname}>
            {fields && record ?
                <React.Fragment>
                    {fields.map((field, index) => (

                        <FieldValue
                            field={field}
                            record={record}
                        />

                    ))}
                </React.Fragment>
                :
                ""
            }
        </div>
    )
})

const ListCardItem = observer(function Cell(props) {

    const { screen, record } = props;
    const default_columns = screen.default_columns
    const primary_fields = screen.mobile_primary_fields
    const secondary_fields = screen.mobile_secondary_fields
    const header_image_field = screen.mobile_record_image_header_field
    const isSelected = screen.is_selected(record.id)

    function getHeaderImage() {

        const field_value = header_image_field.get_value(record)
        const uri = field_value ? field_value.data : false

        if (!uri) {
            return (<React.Fragment></React.Fragment>)
        }

        return (
            <img
                style={{height:'100%'}}
                className='img-fluid'
                src={uri}
            />
        )
    }


    return (

        <Container  type="paper" className="border border-gray-300" shadowClass="shadow-md">
            {(header_image_field || record.mobile_record_title) &&
                <div className="flex px-2 py-2 text-gray-600 text-sm" style={{width:'100%'}}>
                {header_image_field ?
                    <React.Fragment>{getHeaderImage()}</React.Fragment>
                    :
                    <React.Fragment>{record.mobile_record_title}</React.Fragment>

                }
            </div>
            }
            

            {primary_fields.length &&
                <FieldsGrid fields={primary_fields} record={record} default_columns={default_columns} />
            }

        </Container>


    )


});

export default ListCardItem;