import React, { useRef } from 'react';
// import {sheet_navigation_signals} from '../../common/common.js'
// import FocusTrap from 'focus-trap-react'
// import { ReactComponent as Dots } from '../../ui/icons/svg/navigation-more.svg'
import { ReactComponent as Dots } from '../../icons/svg/navigation-more.svg'
import { observer } from 'mobx-react-lite'
import { ReactComponent as SearchIcon } from '../../icons/svg/search.svg';
import { ReactComponent as DownloadIcon } from '../../icons/svg/download.svg';
import IconButton from '../../buttons/IconButton'


function RightIcon(props) {
    const { state_attrs, value, searchFile } = props;

    const SearchRightIcon = () => {

        return (
            <div style={{ maxHeight: '1rem' }}>

                <IconButton style={{ padding: 0 }} onClick={searchFile} color="transparent">
                    <SearchIcon style={{ width: '.8em', height: '1rem' }} className="fill-current  text-xl cursor-pointer w-4 h-2" />
                </IconButton>


            </div>
        )
    }

    const DownloadRightIcon = (props) => {
        return (
            <div style={{ maxHeight: '1rem' }}>

                <IconButton style={{ padding: 0 }} onClick={(e) => { console.log("Open Attachment Finder") }} color="transparent">
                    <DownloadIcon style={{ width: '.8em', height: '1rem' }} className="fill-current  text-xl cursor-pointer w-4 h-2" />
                </IconButton>


            </div>
        )
    }



    return (
        <>
            {value ?
                <DownloadRightIcon />
                :
                (!state_attrs.readonly) ?
                    <SearchRightIcon onCLick={searchFile} />
                    :
                    ""
            }
        </>
    )

}

function BinaryField(props) {

    const { field, state_attrs, record } = props;
    const loading = field.is_loading(record)
    const widget = field.attributes.widget || 'base'
    // const value = field.get_data(record)
    const inputRef = useRef(null);

    function onChangeInput(e) {
        e.stopPropagation();
        e.preventDefault();
        let file = e.target.files[0];
        console.log(file)

        const reader = new FileReader();
        reader.addEventListener('load', (event) => {
            const value = {data:event.target.result.split (",").pop ()}
            field.set_value(value, record)
        });
        reader.readAsDataURL(file);
        
    }
    function searchFile() {
        console.log("Input Ref")
        inputRef.current.click()
    }


    return (
        <div className="flex justify-between">
            {loading ?
                <div className="flex justify-end content-end" style={{ minHeight: '1.5rem' }}>
                    <Dots style={{ width: '1.2em', height: '1.2em' }} className="fill-current text-xl cursor-pointer w-4 h-2 scale-left-to-right" />

                </div>

                :
                <>
                    <div className="text-red-600">Implementation Pending</div>
                    {/* <input
                        style={{ display: "none" }}
                        type='file'
                        onChange={onChangeInput}
                        ref={inputRef}

                    />
                    {props.value ?
                        
                        <img style={{ maxHeight: '90%' }} src={value}></img>
                        :
                        ""
                    } */}
                </>
            }
            {/* <RightIcon value={value} state_attrs={state_attrs} searchFile={searchFile} /> */}
        </div>





    )

        }


export default BinaryField;