import React, {Fragment} from "react";
import { ReactComponent as DeleteIcon } from "./icons/svg/close.svg";
import IconButton from "./buttons/IconButton";
import { observer } from 'mobx-react-lite'
import Chip from "../ui/Chip.js"

const FilterChip = observer(function FilterChip(props) {
  const { filter, record, removeFilter } = props;
  const description = filter.field.description
  const operator_label = filter.operator ? filter.operator.label:false
  const value = filter.field ? filter.field.get_string_value(record):false
  const valid = (description && operator_label && value)

  return (
    <>
      {valid &&
        <div className={`cursor-default text-gray-600 font-semibold`.concat( props.className)}>
          {/* <div className="flex m-2">
            <p >{description}</p>
            <p className="mx-1">|</p>
            <p>{operator_label}</p>
            <p className="mx-1">|</p>
            <p className="text-primary">{value}</p>
          </div> */}
          {!filter.required && (
            <>
              {/* <IconButton color="warning">
                <DeleteIcon className="fill-current h-3"
                  onClick={() => {
                    removeFilter(filter);
                  }}
                />
                </IconButton>*/}
				<Chip
                spaceBetween="mr-2 mb-3"
                text={
                  <>
                    {description}
                    <span className="px-2 text-sm leading-4 uppercase font-light">
                      &#40;
                      <span className="text-xs leading-5 px-1">
                        {operator_label}
                      </span>
                      &#41;
                    </span>
                    "{value}"
                  </>
                }
                onClick={() => {
                  removeFilter(filter);
                }}
              />
            </>
          )}
        </div>
      }

    </>
    )

  }
  )

export const FiltersActive = observer(function FiltersActive(props) {
  const { filters } = props;
  const filtersActive = filters.active_filters.filter(function(f){return !f.quick_filter});
  const record = filters.record;
  const visibleActiveFilters = filters.applied_toolbar_qty

  function removeFilter(filter) {
    filters.removeFilter(filter)
    return filters.search()
  }

  return visibleActiveFilters > 0 ? (
    <div className={`flex flex-row flex-wrap ${
        visibleActiveFilters > 0
          ? "max-h-80 transition-all duration-700 "
          : "max-h-0 transition-all duration-700 "
      }`}
    >
      <p
        className={`${
          visibleActiveFilters > 0 ? "visible " : "invisible "
        } self-center text-xsm uppercase text-gray-600 font-light mr-3 mb-3`}
      >
        Filtros:
      </p>
        {filtersActive.map((filter, index) => (
            <FilterChip
              filter={filter}
              record={record}
              removeFilter={removeFilter}
              key={index}
          className={`${visibleActiveFilters > 0 ? " visible" : " invisible"}`}
            />
      ))}
    </div>
  ) : (
    ""
    )

  }
  )


// toogleFilters() en FilterBar cierre al hacer clic fuera de DropDown 