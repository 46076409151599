import React, { useContext, useState } from 'react';
// import ThemeContext from '../../common/ThemeContext.js'
import { NavLink } from "react-router-dom";
import { ReactComponent as ChevronLeft } from '../icons/cheveron-outline-left.svg';
import { IconButton } from 'kalenis-web-components';
import { observer } from 'mobx-react-lite'
import { ThemeContext, SessionContext, AppContext } from 'kalenis-portal-frontend-main'
import { FormButton } from 'kalenis-web-components'
import { ViewWizardSelector } from 'kalenis-web-components';
import {

    useHistory

} from "react-router-dom";
import clsx from 'clsx';
const paddings = {
    0: "px-2",
    1: "px-4",
    2: "px-6",
    3: "px-8"
}

const default_background = 'rgb(250,250,255)'
const default_text = 'black'


const CategoryMenuItem = observer(function CategoryMenuItem(props) {

    const { item } = props
    const {expanded, childs, name} = item
    const baseClassName = "flex cursor-pointer font-semibold  py-2 border-b-0.5 border-gray-300 text-xs ".concat(paddings[item.level])
    const className = props.className ? props.className.concat(baseClassName) : baseClassName
    const bg_color = item.bg_color ? item.bg_color : default_background
    const color = item.text_color ? item.text_color : default_text
    return (
        <>
            <div onClick={() => { item.select() }} style={{ ...props.style, backgroundColor: bg_color, color: color }} className={className}>
                {props.icon ? props.icon : ""}
                {name}

            </div>
            {(childs && expanded) &&
                <>
                    {childs.map((child, index) => (
                        <React.Fragment key={index}>
                            <MenuItem item={child}/>
                        </React.Fragment>



                    ))}
                </>
            }
        </>
   )

})
const ListItem = observer(function ListItem(props) {

    const { item } = props
    const baseClassName = "flex py-2 border-b-0.5 border-gray-300 text-xs cursor-pointer ".concat(paddings[item.level])
    let className = props.className ? props.className.concat(baseClassName) : baseClassName
    if (item.active) {
        className = clsx(className, 'text-primary font-medium bg-light-hover')
    }

    return (
        <>
            {item.route ?
                <NavLink key={item} to={item.route.path}
                >
                    <div onClick={() => item.select()} className={className}>
                        {props.icon ? props.icon : ""}
                        {item.route.sidebar.title}
                    </div>

                </NavLink>
                :
                <div onClick={() => item.select()} className={className}>
                    {item.name}
                </div>

            }

        </>

)

})

const MenuItem = observer(function MenuItem(props) {
    const { item } = props
    const renderMenu = (item) => {
        if (['category', 'child_records'].includes(item.type)) {

            return (
                <>
                    <CategoryMenuItem item={item} />

                </>
            )
        }
        else {
            return (<ListItem item={item} />)
        }
    }

    return (
        <>
            {renderMenu(item)}
        </>

    )

})

const SideBarMenu = observer(function SidebarMenu(props) {
    const theme = useContext(ThemeContext);
    const session = useContext(SessionContext)
    const view_editor = (session && session.user_context) ? session.user_context.view_editor : false
    const [wizard_dialog, setWizardDialog] = useState(false)
    const menu = session.menu
    const history = useHistory()
    menu.set_navigation(navigate)


    function navigate(path) {
        history.push(path)
    }
    const renderMenu = (item) => {
        if (['category', 'child_records'].includes(item.type)) {

            return (
                <>
                    <CategoryMenuItem item={item} />
                    {(item.childs && item.expanded) &&
                        <>
                            {item.childs.map((child, index) => (
                                <React.Fragment key={index}>
                                    {renderMenu(child)}
                                </React.Fragment>



                            ))}
                        </>
                    }
                </>
            )
        }
        else {
            return (<ListItem item={item} />)
        }
    }


    return (

        <>

            <div className="p-4 border-b-0.5 border-gray-300">
                <img alt="logo" src={theme.logo}></img>
            </div>


            <div style={{ height: props.calcHeight }} className="mb-12 overflow-scroll">
                {menu.tree.map((item, index) => (
                    <React.Fragment key={index}>
                        {/* {renderMenu(item)} */}
                        <MenuItem item={item} />
                    </React.Fragment>



                ))}




            </div>


            <div className="absolute bottom-0 bg-background w-full" >
                <div className="flex flex-col">
                    {view_editor &&
                        <>
                            {wizard_dialog &&
                                <ViewWizardSelector open={wizard_dialog} close={() => { setWizardDialog(false) }} />
                            }

                            <FormButton state_attrs={{}} onClick={() => { setWizardDialog(true) }} color="transparent">+ Nuevo </FormButton>
                        </>
                    }

                    <IconButton onClick={(e) =>{ 
                        props.setMenuWidth(0)
                    }} color="transparent">
                        <ChevronLeft style={{ width: '.8em', height: '.8em' }} className="fill-current  text-xl cursor-pointer w-4 h-2" />
                    </IconButton>
                </div>
            </div>

        </>
    )

})


export default SideBarMenu;