import { makeAutoObservable, autorun, runInAction, action, observable, makeObservable, computed } from "mobx"
import { readFile } from "../common"
export class BinaryValue {
    field = false
    value = null
    name = ""
    data = null
    type = ""
    loading = false
    available_value = null
    id = -1
    
    get rec_name(){
        if(!this.available_value){
            return ""
        }
        const rec_name = this.field.get_string_value(this.record)
        return rec_name ? rec_name:this.name
    }
    constructor(field, record, available_value=null, id=-1) {
        makeObservable(this, {
            value: observable,
            set_value:action,
            set_data:action,
            set_name:action,
            set_type:action,
            reload: action,
            toogleLoading: action,
            name:observable,
            type:observable,
            available_value:observable,
            set_available:action,
            id:observable,
            set_attachment_id:action,
        })
        this.field = field
        this.value = null
        this.loading = false
        this.data = null        
        this.type = ""
        this.available_value = available_value
        this.name = available_value ? "1 Archivo Disponible":""
        this.record = record
        this.id = id ? id:-1
        this.data_changed = false


    }

    get_value() {
        return {
            'id':this.id,
            'name':this.name,
            'data':this.data,
            'type':this.type,
            'data_changed':this.data_changed
        }
    }

    async get_data(value){
        
        if(this.data){
            return this.data
        }
        else{
            return await this.field.get_binary_value(this.record)
        }
        
        return null

    }

    set_data(data){
        this.data = data
    }
    set_name(name){
        this.name = name
    }
    set_type(type){
        this.type = type
    }
    set_available(available){
        this.available_value = available
    }
    set_attachment_id(id){
        this.id = id
    }
    clear_value(){
        this.set_data(null)
        this.set_name("")
        this.set_available(false)
        this.set_attachment_id(-1)
        this.data_changed = true
    }

    set_value(value){
        if(!value){
           
            return this.clear_value()
        }

        readFile({'data':value}, 'data_url').then(function(data){
            this.set_data(data)
            if(value.name){
                this.set_name(value.name)
            }
            if(value.type){
                this.set_type(value.type)
            }
            this.set_available(true)
            this.data_changed = true
        }.bind(this))

       

    }
    

    toogleLoading() {
        this.loading = !this.loading
        // this.loading=true
    }

    async reload(record) {
        //Try to find de value on the record
        this.toogleLoading()
        let value = record._values[this.field.name]
        // if (!value) {
        //     value = await this.field.get_binary_value(record)
            
            
        // }

        runInAction(() => {
            
            this.value = value ? {data:value}:false
            this.toogleLoading()

        })

    }


}