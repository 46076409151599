import React, { useContext, useEffect, useState } from 'react';

import AsyncSelect from 'react-select/async';
import {observer } from 'mobx-react-lite'
import { computed } from 'mobx'


//TODO: should support get its own options or receive options.


const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    borderRadius: '0px',
    marginTop: '0px'
  }),
  container: (provided, state) => ({

    ...provided,
    //height:'100%',
    // backgroundColor: 'red',
    
    




  }),

  control: (provided, state) => ({

    ...provided,
    border: '0px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    // padding: '0px',
    minHeight: '0px',
    top:'10%'
    
    




  }),
  clearIndicator: (provided, state) => ({

    ...provided,
    cursor: 'pointer',
    paddingTop:0,
    paddingBottom:0,
    maxHeight:'1.5rem',
    ':hover': {},
  }),
  indicatorSeparator: (provided, state) => ({

    ...provided,
    cursor: 'pointer',
    margin:0,
    


  }),
  dropdownIndicator: (provided, state) => ({

    ...provided,
    cursor: 'pointer',
    paddingTop:0,
    paddingBottom:0,
    maxHeight:'1.5rem',
    


  }),
  input: (provided, state) => ({

    ...provided,
    margin:0,
    paddingTop:0,
    paddingBottom:0,
    maxHeight:'1.5rem',
    top:'40%'
    


  }),
  valueContainer: (provided, state) => ({

    ...provided,
    cursor: 'pointer',
    paddingTop:0,
    paddingBottom:0,
    paddingLeft:0,
   /*  maxHeight:'1.5rem', */
    paddingRight: 0,
    


  }),
  placeholder: (provided, state) => ({

    ...provided,
    cursor: 'pointer',
    paddingTop:0,
    paddingBottom:0,
    maxHeight:'1.5rem',
    top:'55%',
    color: 'inherit',
    fontWeight: '400',
    opacity: '0.6',
  }),
  singleValue: (provided, state) => ({

    ...provided,
    cursor: 'pointer',
    padding:0,
    maxHeight:'1.5rem',
    overflow:'unset',
    top:'40%'
    
    


  }),
  
  menuPortal: (provided, state) => ({
    ...provided,
    zIndex: 9999,
    minWidth:200
  }),

  option: (provided, state) => {
    return {
      ...provided,
      //color:state.data.color ? state.data.color:"inherit",
      // backgroundColor:state.isSelected ? 'red':undefined
      color: "rgba(113, 128, 150, 1)",
    };
  },

  multiValue: (provided, state) => ({
    ...provided,
    border: "1px solid #CBD5E0",
    backgroundColor: "white",
    borderRadius: "5rem",
    paddingLeft: ".25rem",
    paddingRight: ".25rem",
    marginBottom: ".45rem",
  }),

  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "rgba(113, 128, 150,1)",
    fontWeight: "500",
  }),

  multiValueRemove: (provided, state) => ({
    ...provided,
    ":hover": {},
  }),
}

export const AsyncSelectField = observer(function AsyncSelectField(props) {




  //new
  const { field, record,onBlur } = props;
  const value = field.get_value(record)
  const [wKey, setWkey] = useState("")
  const key_fields = computed(() => field.get_key_fields_values(record)).get()
  // HOOKS
  //TODO: Review
  useEffect(() => {
    // const key_fields = field.get_key_fields_values(record)
    
    let newK = ""
    for(let k in key_fields){
      newK = newK.concat(key_fields[k])
    }
    // Add readonly state to reload in case of change
    newK = newK.concat(props.state_attrs.readonly.toString())
    if(newK!==wKey){
      setWkey(newK)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key_fields, props.state_attrs.readonly]);


  const loadOptions = (inputValue, callback) => {

    if (props.state_attrs.readonly) {
      return callback([])
    }
    field.getOptions(record, inputValue).then(function (options) {

      //Replace when async search implemented
      if (inputValue) {
        options = options.filter(i =>
          i.rec_name.toLowerCase().includes(inputValue.toLowerCase())
        );
      }


      callback(options)


    })


  };

  const onFocus = (e) => {};

  return (

    <AsyncSelect
      id={props.name}
      classNamePrefix={props.classNamePrefix ? props.classNamePrefix : ""}
      name={props.name}
      key={wKey ? wKey : props.name}
      ref={props.inputRef}
      isSearchable
      isClearable
      placeholder={!props.value ? field.description : ""}
      noOptionsMessage={() => { return "" }}
      value={value}
      getOptionLabel={(option) => option.rec_name}
      getOptionValue={(option) => option.id}
      required={props.state_attrs.required}
      isDisabled={props.state_attrs.readonly}
      menuPortalTarget={document.body}
      menuPosition={'fixed'}
      onChange={(value) => { 
        
        field.set_value(value, record) 
        onBlur(false,value)
      }}
      onFocus={onFocus}
      cacheOptions
      defaultOptions
      onBlur={props.saveOnBlur}
      styles={props.styles ? props.styles : customStyles}
      onKeyDown={props.handleKeyDown}
      loadOptions={loadOptions}
      autoFocus={props.autoFocus}
      isMulti={props.multi}


    />



  )
})

export default AsyncSelectField;