import React, { useContext} from 'react';
import { AppContext } from 'kalenis-portal-frontend-main'
import {View_} from 'kalenis-web-components'
import CustomLoader from './CustomLoader';
import {

    useLocation,
    useHistory

} from "react-router-dom";

import { common } from 'kalenis-web-components';

export function CreateView(props) {
    const app_context = useContext(AppContext)
    const history = useHistory()


    function useQuery() {

        return new URLSearchParams(useLocation().search);
    }
    let queryParams = useQuery()
    const initial_search = common.extractFilters(queryParams)
    const route_state = history.location.state || {}
    const screen = app_context.addScreen(props.view, history.push, initial_search, route_state)
    console.log("SCREEN ON CREATE VIEW")
    console.log(screen)
    


    return (
        <>
        {screen ?
            <>  

                {screen.type != 'custom' ?
                    <View_ screen={screen} navigate={props.navigate}/>
                    :
                    <CustomLoader screen={screen} navigate={props.navigate}/>
                }
                
            </>
            
            :
            <div>This View is not available in offline mode </div>
        }
       </>
        
        



    )
}