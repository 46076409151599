import React from 'react';

import { observer } from 'mobx-react-lite'
import clsx from 'clsx';

const LeftSideCell = observer(function LeftSideCell(props) {

    const { rowIndex, changeSelection, screen, className, cell_key, style, isSpreadsheet } = props;
    const isSelected = screen.is_selected(screen.get_record_by_index(rowIndex).id)
    let cls = isSelected ? clsx(className, "bg-light-selected"):className
    if(screen.readonly && isSpreadsheet){
        cls = clsx(cls, 'border border-gray-300 bg-gray-200')
    }

    return (
        <div className={cls}  >
            {/* <LeftSideCell screen={screen} rowIndex={rowIndex} changeSelection={changeSelection}/> */}
            <input
                checked={isSelected}
                type="checkbox"
                size="small"
                color="primary"
                style={{ padding: 0 }}
                onChange={(e) => { changeSelection(e, rowIndex) }}


            />
        </div>

    )


});

export default LeftSideCell;