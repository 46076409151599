import React, {useEffect, useState } from 'react';
import {View_} from '../../views/View.js'
import { observer } from 'mobx-react-lite'
import { computed } from 'mobx'
import clsx from 'clsx';

const O2MField = observer(function O2MField(props) {

    const {field, record, state_attrs} = props;
    // const height = field.height ? (parseInt(field.height)*70)/100: (300*70)/100
    const key_fields = computed(() => field.get_key_fields_values(record)).get()
    const error = record._errors[field.name]
    const error_cls = error ? "border-red-600 border-2 rounded-lg":""
    const wrapperRef = React.useRef(null);
    const [height, setHeight] = useState(field.height ? (parseInt(field.height)*70)/100: (300*70)/100)

    useEffect(() => {

        
        field.initialize(record)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record.id]);

    
  //TODO: Review
  useEffect(() => {
    
    if(key_fields){
        field.reload(record)
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key_fields]);

  useEffect(() => {

    field.screen.set_readonly(state_attrs.readonly)

    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [state_attrs.readonly]);

useEffect(() => {
    if (!wrapperRef.current || !field.parent.use_view_layout) return; // wait for the elementRef to be available
    const resizeObserver = new ResizeObserver(() => {
    if(wrapperRef.current){
        const new_height = (wrapperRef.current.getBoundingClientRect().height-30)*0.85
        setHeight(new_height)
    }
    
     
    });
    resizeObserver.observe(wrapperRef.current);
    return () => resizeObserver.disconnect(); // clean up 
  }, []);

    

    return (

       
        <div ref={wrapperRef} className={clsx("overflow-hidden", error_cls)} style={{ overflow: 'hidden', height: '100%', paddingTop: '0.5rem' }}>
            
            {field.screen && 
                <View_ screen={field.screen} height={height}/>
            }
            
           

        </div>



    )

});

export default O2MField;